<template>
  <v-card>
    <v-card-title>
      {{ $t("systemMenu.manage_residents.new_resident_name") }}:
      <v-text-field
        v-model="residentName"
        type="text"
        append-icon="mdi-account-edit"
        class="ml-2"
        :label="$t('systemMenu.manage_residents.resident_name')"
      />
    </v-card-title>
    <v-btn class="primary ml-1 mb-1" text @click="editResidentName">
      {{ $t("systemMenu.manage_residents.save") }}
    </v-btn>
    <v-btn
      class="primary ml-2 mb-1"
      text
      :to="{
        name: 'manageResidents',
        params: { resident },
      }"
    >
      {{ $t("systemMenu.manage_residents.cancel") }}
    </v-btn>

    <v-dialog v-model="showNoNameError" persistent max-width="550">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("systemMenu.manage_residents.insert_name") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showNoNameError = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showServerError" persistent max-width="560px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t("systemMenu.manage_residents.server_error")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showServerError = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { ILLI_API_SERVER_URL } from "../config";

export default {
  mounted() {
    this.resident = this.$route.params.resident;
    this.residentName = this.resident.name;
    if (this.resident.id === undefined) {
      this.$router.push("residents");
    }
  },

  data() {
    return {
      resident: {},
      residentName: "",
      showNoNameError: false,
      showServerError: false,
    };
  },
  computed: {
    ...mapGetters({
      system: "system/system",
      user: "auth/user",
    }),
  },
  methods: {
    editResidentName() {
      if (this.residentName == "") {
        this.showNoNameError = true;
      } else {
        axios
          .patch(
            `${ILLI_API_SERVER_URL}/residents/${this.resident.id}`,
            { name: this.residentName } 
          )
          .then((response) => {
            if (response.status === 200) {
              this.$router.push("residents");
            }
          })
          .catch(() => {
            this.showServerError = true;
          });
        this.residentName = "";
      }
    },
  },
};
</script>